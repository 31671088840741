Vue.component('sliderSectionComponent',{
    props:['items','config'],
    computed:{
        heigtht:function(){
            if(this.config.heigtht)
                return this.config.heigtht;
            return 412
        },
        styleObject:function(){
            if(this.config.heigtht)
                return {'max-height':this.config.heigtht,'background-size': '100%','background-color': 'white' ,'background-repeat': 'no-repeat'}
            return {'max-height':'280px','background-size': '100%', 'background-repeat': 'no-repeat','background-color': 'white' }
        }
    },
    mounted:function(){
        if(this.items.length>0) {
            try {
                $(this.$el).slider();
            } catch (e) {
                console.log('Slider con error', this.$el, this.items);
            }
        }
    },
    updated(){
        if(this.items.length>0) {
            try {
                $(this.$el).slider();
            } catch (e) {
                console.log('Slider con error', this.$el, this.items);
            }
        }
    },
    methods:{
        isAppRouter(item) {
            if(item.fields.ItemLink && item.fields.ItemLink.length>0 && item.fields.ItemLink[0]==='#')
                return true
            return false
        }
    },
    template: `<div :key="'slider-'+getUI()" :id="'slider-'+getUI()" class="slider" ref="slider" v-if="items.length>0" v-bind:style="styleObject">
                    <ul class="slides" v-bind:style="styleObject" >
                       <template v-for="item in items">
                            <li :key="'slider-'+getUI()" v-bind:style="styleObject" v-if="item.fields.ItemPhotoUri!=undefined">
                                <template v-if="isAppRouter(item)">
                                    <router-link class="carousel-item center" :to="item.fields.ItemLink.substring(1)" v-bind:style="styleObject">
                                        <img :src="item.fields.ItemPhotoUri" width="1100"  height="412" alt="" v-bind:style="styleObject">
                                    </router-link>
                                </template>
                                <template v-else>
                                    <a class="carousel-item center" target="_blank" :href="item.fields.ItemLink" v-bind:style="styleObject">
                                        <img :src="item.fields.ItemPhotoUri" width="1100"  height="412" alt="" v-bind:style="styleObject">
                                    </a>
                                </template>
                            </li>
                       </template>
                    </ul>
                </div>`,
});
